*,
::before,
::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

#root {
	position: relative;
}

body {
	overscroll-behavior: none;
}

html {
	scroll-behavior: smooth;
	background: #1d242a;
}
